import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Card } from "@material-ui/core";
import OktaSignInWidget from "./OktaSignInWidget";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "../../../shared/Components";

import "./login.css";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "scroll",
    paddingTop: 20,
    height: "calc(100% - 95px) !important",
    transition: "background-color 0.6s linear",
  },
  signupCard: {
    width: 400,
    margin: "auto",
    paddingTop: 20,
    paddingBottom: 20,
  },
  signupCardContainer: {
    textAlign: "center",
  },
}));

const Login = ({ config }) => {
  const classes = useStyles();
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    document.body.classList.add("home-body");

    return () => {
      document.body.classList.remove("home-body");
    };
  });

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log("error logging in", err);
  };

  if (!authState) return <Menu />;

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <>
      <Menu />
      <div className={classes.root}>
        <OktaSignInWidget
          config={config}
          onSuccess={onSuccess}
          onError={onError}
        />
        <div className={classes.signupCardContainer}>
          <Card className={classes.signupCard} variant="outlined">
            New here?
            <Button
              color="primary"
              variant="text"
              className={classes.signup}
              onClick={() => {
                history.push("/signup");
              }}
            >
              Create an account.
            </Button>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Login;
