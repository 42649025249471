import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Menu } from "../shared/Components";
import { UserApi } from "./Api";
import "./signup.css";

const useStyles = makeStyles({
  root: {
    height: "calc(100% - 60px) !important",
    textAlign: "center",
    overflowY: "auto",
    overflowX: "hidden",
    transition: "background-color 0.6s linear",
    marginBottom: 20,
  },
  heading: {
    textAlign: "center",
  },
  subheading: {
    textAlign: "center",
  },
  signupForm: {
    marginBottom: 50,
    maxWidth: 400,
    margin: "auto",
    padding: 10,
    textAlign: "left",
  },
  input: {
    display: "flex",
    margin: 10,
  },
  inputDual: {
    display: "flex",
    margin: 10,
    marginBottom: 0,
  },
  submit: {
    display: "flex",
    margin: 10,
    marginTop: 20,
  },
  fullNameContainer: {
    display: "flex",
  },
  description: {
    maxWidth: 400,
    margin: "auto",
    textAlign: "left",
    marginBottom: 20,
  },
  signin: {
    display: "flex",
    margin: "auto",
  },
  genericError: {
    marginLeft: 10,
  },
});

function Signup() {
  const recaptchaRef = useRef({});

  const classes = useStyles();
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [success, setSuccess] = useState(false);
  const [busy, setBusy] = useState(false);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [genericError, setGenericError] = useState("");

  useEffect(() => {
    document.body.classList.add("signup-body");

    return () => {
      document.body.classList.remove("signup-body");
    };
  });

  const capitalize = (sentence) => {
    return sentence.charAt(0).toUpperCase() + sentence.substr(1).toLowerCase();
  };

  const handleSubmit = async () => {
    setBusy(true);
    setPasswordError("");
    setEmailError("");
    setFirstNameError("");
    setLastNameError("");

    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    try {
      const recaptcha = await recaptchaRef.current.executeAsync();
      await UserApi.createUser(
        {
          firstName,
          lastName,
          email,
          password,
        },
        recaptcha
      );
      setSuccess(true);
    } catch (errs) {
      recaptchaRef.current.reset();
      try {
        errs.forEach((err) => {
          const { description, fieldName } = err || {};

          switch (fieldName) {
            case "recaptcha":
              setGenericError(description);
              break;
            case "user.password":
              setPasswordError(capitalize(description));
              break;
            case "user.email":
              setEmailError(capitalize(description));
              break;
            case "user.firstName":
              setFirstNameError(capitalize(description));
              break;
            case "user.lastName":
              setLastNameError(capitalize(description));
              break;
            case "okta":
              if (description.startsWith("password:")) {
                setPasswordError(description.replace("password:", ""));
              }
              if (description.startsWith("login:")) {
                if (
                  description.indexOf(
                    "An object with this field already exists in the current organization"
                  ) > -1
                ) {
                  setEmailError("Email address is already in use");
                } else {
                  setEmailError(description.replace("login:", ""));
                }
              }
              if (description.startsWith("firstName:")) {
                setFirstNameError(description.replace("firstName:", ""));
              }
              if (description.startsWith("lastName:")) {
                setLastNameError(description.replace("lastName:", ""));
              }
              break;
            default:
          }
        });
      } catch (finalError) {
        setGenericError(
          "Could not create an account at this time. Please try again later."
        );
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      <Menu />
      <div className={classes.root}>
        {!success ? (
          <>
            <h1 className={classes.heading}>Create An Account</h1>
            <div className={classes.description}>
              <p>
                This site integrates with the Okta identity platform for
                authentication. It is very widely used nowadays:{" "}
                <a
                  href="https://www.okta.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more.
                </a>
              </p>
              <p>
                By creating an account you are <b>not</b> signing up for any
                email spam. The only purpose of collecting your email is to send
                you a password reset if you ever forget your login.
              </p>
            </div>
            <Card className={classes.signupForm} variant="outlined">
              <div className={classes.fullNameContainer}>
                <FormControl
                  sx={{ m: 1 }}
                  variant="standard"
                  className={classes.inputDual}
                >
                  <InputLabel
                    error={!!firstNameError}
                    required
                    htmlFor="first-name"
                  >
                    First Name
                  </InputLabel>
                  <TextField
                    error={!!firstNameError}
                    id="first-name"
                    type="text"
                    data-testid="first-name-input"
                    value={firstName}
                    onChange={(e) => {
                      setFirstNameError("");
                      setFirstName(e.target.value);
                    }}
                  />
                  <FormHelperText
                    id="first-name-helper-text"
                    error={!!firstNameError}
                  >
                    {firstNameError}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  sx={{ m: 1 }}
                  variant="standard"
                  className={classes.inputDual}
                >
                  <InputLabel
                    error={!!lastNameError}
                    required
                    htmlFor="last-name"
                  >
                    Last Name
                  </InputLabel>
                  <Input
                    error={!!lastNameError}
                    id="last-name"
                    type="text"
                    value={lastName}
                    onChange={(e) => {
                      setLastNameError("");
                      setLastName(e.target.value);
                    }}
                  />
                  <FormHelperText
                    id="last-name-helper-text"
                    error={!!lastNameError}
                  >
                    {lastNameError}
                  </FormHelperText>
                </FormControl>
              </div>
              <FormControl
                sx={{ m: 1 }}
                variant="standard"
                className={classes.input}
              >
                <InputLabel error={!!emailError} required htmlFor="email">
                  Email
                </InputLabel>
                <Input
                  error={!!emailError}
                  id="email"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmailError("");
                    setEmail(e.target.value);
                  }}
                />
                <FormHelperText id="email-helper-text" error={!!emailError}>
                  {emailError}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                className={classes.input}
              >
                <InputLabel error={!!passwordError} required htmlFor="password">
                  Password
                </InputLabel>
                <Input
                  error={!!passwordError}
                  id="password"
                  required={true}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPasswordError("");
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword(
                            (prevShowPassword) => !prevShowPassword
                          )
                        }
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  id="password-helper-text"
                  error={!!passwordError}
                >
                  {passwordError}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                className={classes.input}
              >
                <InputLabel
                  error={!!confirmPasswordError}
                  required
                  htmlFor="confirm-password"
                >
                  Confirm Password
                </InputLabel>
                <Input
                  error={!!confirmPasswordError}
                  id="confirm-password"
                  required={true}
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPasswordError("");
                    setConfirmPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(
                            (prevConfirmShowPassword) =>
                              !prevConfirmShowPassword
                          )
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  id="confirm-password-helper-text"
                  error={!!confirmPasswordError}
                >
                  {confirmPasswordError}
                </FormHelperText>
              </FormControl>

              <Button
                color="primary"
                className={classes.submit}
                variant="contained"
                onClick={handleSubmit}
              >
                Sign Up
              </Button>
              <FormHelperText
                className={classes.genericError}
                id="submit-helper-text"
                error={!!genericError}
              >
                {genericError}
              </FormHelperText>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcxoaUcAAAAAKdWoq3MUVa44LrHX87O6UNe1rpQ"
                size="invisible"
                badge="bottomleft"
              />
              {busy && <LinearProgress />}
            </Card>
          </>
        ) : (
          <>
            <h1 className={classes.heading}>Success!</h1>
            <Button
              color="primary"
              className={classes.signin}
              variant="contained"
              disabled={busy}
              onClick={() => {
                history.push("/login");
              }}
            >
              Sign In
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default Signup;
