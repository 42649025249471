// These definitions come from TCGPlayer: /catalog/categories/3/printings
export const groupIds = {
  2807: {
    groupId: 2807,
    name: "SWSH06: Chilling Reign",
    abbreviation: "SWSH06",
    isSupplemental: false,
    publishedOn: "2021-06-18T00:00:00",
    modifiedOn: "2021-04-09T13:57:38.793",
    categoryId: 3,
  },
  2765: {
    groupId: 2765,
    name: "SWSH05: Battle Styles",
    abbreviation: "SWSH05",
    isSupplemental: false,
    publishedOn: "2021-03-19T00:00:00",
    modifiedOn: "2021-05-09T01:33:29.87",
    categoryId: 3,
  },
  2776: {
    groupId: 2776,
    name: "First Partner Pack",
    abbreviation: "FPP",
    isSupplemental: true,
    publishedOn: "2021-02-26T00:00:00",
    modifiedOn: "2021-02-24T15:23:14.697",
    categoryId: 3,
  },
  2754: {
    groupId: 2754,
    name: "Shining Fates",
    abbreviation: "SHF",
    isSupplemental: false,
    publishedOn: "2021-02-19T00:00:00",
    modifiedOn: "2021-05-07T18:31:56.693",
    categoryId: 3,
  },
  2781: {
    groupId: 2781,
    name: "Shining Fates: Shiny Vault",
    abbreviation: "SHFSV",
    isSupplemental: false,
    publishedOn: "2021-02-19T00:00:00",
    modifiedOn: "2021-05-09T19:06:49.163",
    categoryId: 3,
  },
  2782: {
    groupId: 2782,
    name: "McDonald's 25th Anniversary Promos",
    abbreviation: "MCD21",
    isSupplemental: true,
    publishedOn: "2021-02-09T00:00:00",
    modifiedOn: "2021-02-18T21:44:48.04",
    categoryId: 3,
  },
  2701: {
    groupId: 2701,
    name: "SWSH04: Vivid Voltage",
    abbreviation: "SWSH04",
    isSupplemental: false,
    publishedOn: "2020-11-13T00:00:00",
    modifiedOn: "2021-05-09T13:28:15.703",
    categoryId: 3,
  },
  2685: {
    groupId: 2685,
    name: "Champion's Path",
    abbreviation: "CHP",
    isSupplemental: false,
    publishedOn: "2020-09-25T00:00:00",
    modifiedOn: "2021-05-03T23:29:53.853",
    categoryId: 3,
  },
  2675: {
    groupId: 2675,
    name: "SWSH03: Darkness Ablaze",
    abbreviation: "SWSH03",
    isSupplemental: false,
    publishedOn: "2020-08-14T00:00:00",
    modifiedOn: "2021-04-30T23:14:12.18",
    categoryId: 3,
  },
  2686: {
    groupId: 2686,
    name: "Battle Academy",
    abbreviation: "BTA",
    isSupplemental: true,
    publishedOn: "2020-07-31T00:00:00",
    modifiedOn: "2021-04-20T20:07:23.527",
    categoryId: 3,
  },
  2626: {
    groupId: 2626,
    name: "SWSH02: Rebel Clash",
    abbreviation: "SWSH02",
    isSupplemental: false,
    publishedOn: "2020-05-01T00:00:00",
    modifiedOn: "2021-05-07T16:52:46.207",
    categoryId: 3,
  },
  2585: {
    groupId: 2585,
    name: "SWSH01: Sword & Shield Base Set",
    abbreviation: "SWSH01",
    isSupplemental: false,
    publishedOn: "2020-02-07T00:00:00",
    modifiedOn: "2021-05-06T16:11:29.517",
    categoryId: 3,
  },
  2545: {
    groupId: 2545,
    name: "SWSH: Sword & Shield Promo Cards",
    abbreviation: "SWSD",
    isSupplemental: false,
    publishedOn: "2019-11-15T00:00:00",
    modifiedOn: "2021-05-07T16:53:55.087",
    categoryId: 3,
  },
  2534: {
    groupId: 2534,
    name: "SM - Cosmic Eclipse",
    abbreviation: "SM12",
    isSupplemental: false,
    publishedOn: "2019-11-01T00:00:00",
    modifiedOn: "2021-05-08T19:37:26.917",
    categoryId: 3,
  },
  2555: {
    groupId: 2555,
    name: "McDonald's Promos 2019",
    abbreviation: "MCD19",
    isSupplemental: false,
    publishedOn: "2019-10-01T00:00:00",
    modifiedOn: "2021-02-22T17:46:07.457",
    categoryId: 3,
  },
  2480: {
    groupId: 2480,
    name: "Hidden Fates",
    abbreviation: "HIF",
    isSupplemental: false,
    publishedOn: "2019-08-23T00:00:00",
    modifiedOn: "2021-05-04T12:57:59.107",
    categoryId: 3,
  },
  2594: {
    groupId: 2594,
    name: "Hidden Fates: Shiny Vault",
    abbreviation: "HIF:SV",
    isSupplemental: false,
    publishedOn: "2019-08-23T00:00:00",
    modifiedOn: "2021-05-09T13:26:12.187",
    categoryId: 3,
  },
  2464: {
    groupId: 2464,
    name: "SM - Unified Minds",
    abbreviation: "SM11",
    isSupplemental: false,
    publishedOn: "2019-08-02T00:00:00",
    modifiedOn: "2021-05-03T23:29:09.58",
    categoryId: 3,
  },
  2420: {
    groupId: 2420,
    name: "SM - Unbroken Bonds",
    abbreviation: "SM10",
    isSupplemental: false,
    publishedOn: "2019-05-03T00:00:00",
    modifiedOn: "2021-05-03T15:34:13.883",
    categoryId: 3,
  },
  2409: {
    groupId: 2409,
    name: "Detective Pikachu",
    abbreviation: "DEP",
    isSupplemental: false,
    publishedOn: "2019-04-05T00:00:00",
    modifiedOn: "2021-04-27T21:30:22.267",
    categoryId: 3,
  },
  2377: {
    groupId: 2377,
    name: "SM - Team Up",
    abbreviation: "SM9",
    isSupplemental: false,
    publishedOn: "2019-02-01T00:00:00",
    modifiedOn: "2021-05-04T12:54:21.667",
    categoryId: 3,
  },
  2328: {
    groupId: 2328,
    name: "SM - Lost Thunder",
    abbreviation: "SM8",
    isSupplemental: false,
    publishedOn: "2018-11-02T00:00:00",
    modifiedOn: "2021-05-09T16:29:38.05",
    categoryId: 3,
  },
  2364: {
    groupId: 2364,
    name: "McDonald's Promos 2018",
    abbreviation: "MCD18",
    isSupplemental: true,
    publishedOn: "2018-11-02T00:00:00",
    modifiedOn: "2021-02-23T15:50:17.63",
    categoryId: 3,
  },
  2374: {
    groupId: 2374,
    name: "Miscellaneous Cards & Products",
    abbreviation: "MCAP",
    isSupplemental: true,
    publishedOn: "2018-11-01T00:00:00",
    modifiedOn: "2021-05-09T13:27:06.577",
    categoryId: 3,
  },
  2295: {
    groupId: 2295,
    name: "Dragon Majesty",
    abbreviation: "DRM",
    isSupplemental: false,
    publishedOn: "2018-09-07T00:00:00",
    modifiedOn: "2021-04-20T13:22:09.067",
    categoryId: 3,
  },
  2278: {
    groupId: 2278,
    name: "SM - Celestial Storm",
    abbreviation: "CES",
    isSupplemental: false,
    publishedOn: "2018-08-03T00:00:00",
    modifiedOn: "2021-05-07T13:16:30.407",
    categoryId: 3,
  },
  2282: {
    groupId: 2282,
    name: "World Championship Decks",
    abbreviation: "WCD",
    isSupplemental: false,
    publishedOn: "2018-07-01T00:00:00",
    modifiedOn: "2019-09-27T19:02:25.797",
    categoryId: 3,
  },
  2209: {
    groupId: 2209,
    name: "SM - Forbidden Light",
    abbreviation: "SM06",
    isSupplemental: false,
    publishedOn: "2018-05-04T00:00:00",
    modifiedOn: "2021-05-08T17:00:47.73",
    categoryId: 3,
  },
  2208: {
    groupId: 2208,
    name: "SM Trainer Kit: Alolan Sandslash & Alolan Ninetales",
    abbreviation: "SMK2",
    isSupplemental: true,
    publishedOn: "2018-04-06T00:00:00",
    modifiedOn: "2018-06-06T18:12:12.783",
    categoryId: 3,
  },
  2178: {
    groupId: 2178,
    name: "SM - Ultra Prism",
    abbreviation: "SM05",
    isSupplemental: false,
    publishedOn: "2018-02-02T00:00:00",
    modifiedOn: "2021-05-09T16:29:58.293",
    categoryId: 3,
  },
  2148: {
    groupId: 2148,
    name: "McDonald's Promos 2017",
    abbreviation: "MCD17",
    isSupplemental: true,
    publishedOn: "2017-11-07T00:00:00",
    modifiedOn: "2019-09-26T14:57:52.67",
    categoryId: 3,
  },
  2071: {
    groupId: 2071,
    name: "SM - Crimson Invasion",
    abbreviation: "SM04",
    isSupplemental: false,
    publishedOn: "2017-11-03T00:00:00",
    modifiedOn: "2021-05-06T13:55:57.693",
    categoryId: 3,
  },
  2054: {
    groupId: 2054,
    name: "Shining Legends",
    abbreviation: "SHL",
    isSupplemental: false,
    publishedOn: "2017-10-06T00:00:00",
    modifiedOn: "2021-04-16T12:31:55.06",
    categoryId: 3,
  },
  1957: {
    groupId: 1957,
    name: "SM - Burning Shadows",
    abbreviation: "SM03",
    isSupplemental: false,
    publishedOn: "2017-08-04T00:00:00",
    modifiedOn: "2021-05-09T13:26:43.31",
    categoryId: 3,
  },
  1938: {
    groupId: 1938,
    name: "Alternate Art Promos",
    abbreviation: "PR",
    isSupplemental: true,
    publishedOn: "2017-05-19T00:00:00",
    modifiedOn: "2021-05-08T09:58:04.377",
    categoryId: 3,
  },
  1919: {
    groupId: 1919,
    name: "SM - Guardians Rising",
    abbreviation: "SM02",
    isSupplemental: false,
    publishedOn: "2017-05-05T00:00:00",
    modifiedOn: "2021-05-08T17:00:53.59",
    categoryId: 3,
  },
  2069: {
    groupId: 2069,
    name: "SM Trainer Kit: Lycanroc & Alolan Raichu",
    abbreviation: "SMK1",
    isSupplemental: false,
    publishedOn: "2017-04-21T00:00:00",
    modifiedOn: "2021-04-06T13:10:25.22",
    categoryId: 3,
  },
  1863: {
    groupId: 1863,
    name: "SM Base Set",
    abbreviation: "SM01",
    isSupplemental: false,
    publishedOn: "2017-02-03T00:00:00",
    modifiedOn: "2021-05-07T13:02:28.12",
    categoryId: 3,
  },
  1861: {
    groupId: 1861,
    name: "SM Promos",
    abbreviation: "SMP",
    isSupplemental: false,
    publishedOn: "2016-12-14T00:00:00",
    modifiedOn: "2021-05-09T15:20:15.177",
    categoryId: 3,
  },
  1842: {
    groupId: 1842,
    name: "XY - Evolutions",
    abbreviation: "EVO",
    isSupplemental: false,
    publishedOn: "2016-11-02T00:00:00",
    modifiedOn: "2021-05-01T17:06:23.74",
    categoryId: 3,
  },
  1840: {
    groupId: 1840,
    name: "Deck Exclusives",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2016-09-01T00:00:00",
    modifiedOn: "2021-05-07T13:54:32.753",
    categoryId: 3,
  },
  1815: {
    groupId: 1815,
    name: "XY - Steam Siege",
    abbreviation: "STS",
    isSupplemental: false,
    publishedOn: "2016-08-03T00:00:00",
    modifiedOn: "2021-05-06T13:48:26.107",
    categoryId: 3,
  },
  1539: {
    groupId: 1539,
    name: "League & Championship Cards",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2016-06-01T00:00:00",
    modifiedOn: "2021-05-07T16:54:42.627",
    categoryId: 3,
  },
  1780: {
    groupId: 1780,
    name: "XY - Fates Collide",
    abbreviation: "FCO",
    isSupplemental: false,
    publishedOn: "2016-05-02T00:00:00",
    modifiedOn: "2021-05-08T13:59:00.337",
    categoryId: 3,
  },
  1796: {
    groupId: 1796,
    name: "XY Trainer Kit: Pikachu Libre & Suicune",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2016-04-27T00:00:00",
    modifiedOn: "2020-09-08T15:10:56.707",
    categoryId: 3,
  },
  1728: {
    groupId: 1728,
    name: "Generations",
    abbreviation: "GEN",
    isSupplemental: false,
    publishedOn: "2016-02-22T00:00:00",
    modifiedOn: "2021-04-28T12:10:25.063",
    categoryId: 3,
  },
  1729: {
    groupId: 1729,
    name: "Generations: Radiant Collection",
    abbreviation: "GEN",
    isSupplemental: false,
    publishedOn: "2016-02-22T00:00:00",
    modifiedOn: "2021-04-19T12:12:40.613",
    categoryId: 3,
  },
  1701: {
    groupId: 1701,
    name: "XY - BREAKpoint",
    abbreviation: "BKP",
    isSupplemental: false,
    publishedOn: "2016-02-03T00:00:00",
    modifiedOn: "2021-05-06T12:59:17.667",
    categoryId: 3,
  },
  1694: {
    groupId: 1694,
    name: "McDonald's Promos 2015",
    abbreviation: "MCD15",
    isSupplemental: false,
    publishedOn: "2015-11-23T00:00:00",
    modifiedOn: "2017-11-27T20:29:06.273",
    categoryId: 3,
  },
  1661: {
    groupId: 1661,
    name: "XY - BREAKthrough",
    abbreviation: "BKT",
    isSupplemental: false,
    publishedOn: "2015-11-04T00:00:00",
    modifiedOn: "2021-05-07T17:53:31.37",
    categoryId: 3,
  },
  1576: {
    groupId: 1576,
    name: "XY - Ancient Origins",
    abbreviation: "AOR",
    isSupplemental: false,
    publishedOn: "2015-08-12T00:00:00",
    modifiedOn: "2021-04-19T14:05:50.51",
    categoryId: 3,
  },
  1534: {
    groupId: 1534,
    name: "XY - Roaring Skies",
    abbreviation: "ROS",
    isSupplemental: false,
    publishedOn: "2015-05-06T00:00:00",
    modifiedOn: "2021-05-07T13:17:00.79",
    categoryId: 3,
  },
  1536: {
    groupId: 1536,
    name: "XY Trainer Kit: Latias & Latios",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2015-04-29T00:00:00",
    modifiedOn: "2021-03-05T12:38:35.463",
    categoryId: 3,
  },
  1528: {
    groupId: 1528,
    name: "Jumbo Cards",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2015-04-01T00:00:00",
    modifiedOn: "2021-05-07T21:17:20.417",
    categoryId: 3,
  },
  1525: {
    groupId: 1525,
    name: "Double Crisis",
    abbreviation: "DCR",
    isSupplemental: false,
    publishedOn: "2015-03-25T00:00:00",
    modifiedOn: "2020-12-16T18:37:41.813",
    categoryId: 3,
  },
  1509: {
    groupId: 1509,
    name: "XY - Primal Clash",
    abbreviation: "PRC",
    isSupplemental: false,
    publishedOn: "2015-02-04T00:00:00",
    modifiedOn: "2021-05-04T15:15:02.247",
    categoryId: 3,
  },
  1533: {
    groupId: 1533,
    name: "XY Trainer Kit: Bisharp & Wigglytuff",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2014-11-14T00:00:00",
    modifiedOn: "2020-08-21T13:32:09.9",
    categoryId: 3,
  },
  1494: {
    groupId: 1494,
    name: "XY - Phantom Forces",
    abbreviation: "PHF",
    isSupplemental: false,
    publishedOn: "2014-11-05T00:00:00",
    modifiedOn: "2021-05-06T12:59:26.667",
    categoryId: 3,
  },
  1481: {
    groupId: 1481,
    name: "XY - Furious Fists",
    abbreviation: "FFI",
    isSupplemental: false,
    publishedOn: "2014-08-13T00:00:00",
    modifiedOn: "2021-05-07T17:59:34.293",
    categoryId: 3,
  },
  1692: {
    groupId: 1692,
    name: "McDonald's Promos 2014",
    abbreviation: "MCD14",
    isSupplemental: false,
    publishedOn: "2014-05-23T00:00:00",
    modifiedOn: "2017-12-26T20:20:25.843",
    categoryId: 3,
  },
  1464: {
    groupId: 1464,
    name: "XY - Flashfire",
    abbreviation: "FLF",
    isSupplemental: false,
    publishedOn: "2014-05-07T00:00:00",
    modifiedOn: "2021-05-09T19:06:30.247",
    categoryId: 3,
  },
  1532: {
    groupId: 1532,
    name: "XY Trainer Kit: Sylveon & Noivern",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2014-03-12T00:00:00",
    modifiedOn: "2021-01-27T14:37:29.22",
    categoryId: 3,
  },
  1387: {
    groupId: 1387,
    name: "XY Base Set",
    abbreviation: "XY",
    isSupplemental: false,
    publishedOn: "2014-02-05T00:00:00",
    modifiedOn: "2021-05-07T14:43:29.163",
    categoryId: 3,
  },
  1451: {
    groupId: 1451,
    name: "XY Promos",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2013-12-16T00:00:00",
    modifiedOn: "2021-04-22T14:41:52.42",
    categoryId: 3,
  },
  1409: {
    groupId: 1409,
    name: "Legendary Treasures",
    abbreviation: "LTR",
    isSupplemental: false,
    publishedOn: "2013-11-06T00:00:00",
    modifiedOn: "2021-04-02T18:04:44.627",
    categoryId: 3,
  },
  1465: {
    groupId: 1465,
    name: "Legendary Treasures: Radiant Collection",
    abbreviation: "LTR",
    isSupplemental: false,
    publishedOn: "2013-11-06T00:00:00",
    modifiedOn: "2021-04-16T13:25:58.783",
    categoryId: 3,
  },
  1370: {
    groupId: 1370,
    name: "Plasma Blast",
    abbreviation: "PLB",
    isSupplemental: false,
    publishedOn: "2013-08-14T00:00:00",
    modifiedOn: "2021-03-23T18:27:36.563",
    categoryId: 3,
  },
  1382: {
    groupId: 1382,
    name: "Plasma Freeze",
    abbreviation: "PLF",
    isSupplemental: false,
    publishedOn: "2013-05-08T00:00:00",
    modifiedOn: "2021-05-07T13:52:50.08",
    categoryId: 3,
  },
  1413: {
    groupId: 1413,
    name: "Plasma Storm",
    abbreviation: "PLS",
    isSupplemental: false,
    publishedOn: "2013-02-06T00:00:00",
    modifiedOn: "2021-05-07T13:55:34.69",
    categoryId: 3,
  },
  1408: {
    groupId: 1408,
    name: "Boundaries Crossed",
    abbreviation: "BCR",
    isSupplemental: false,
    publishedOn: "2012-11-07T00:00:00",
    modifiedOn: "2021-05-03T14:43:46.573",
    categoryId: 3,
  },
  1394: {
    groupId: 1394,
    name: "Dragons Exalted",
    abbreviation: "DRX",
    isSupplemental: false,
    publishedOn: "2012-08-15T00:00:00",
    modifiedOn: "2021-04-13T15:36:05.067",
    categoryId: 3,
  },
  1386: {
    groupId: 1386,
    name: "Dark Explorers",
    abbreviation: "DEX",
    isSupplemental: false,
    publishedOn: "2012-05-09T00:00:00",
    modifiedOn: "2021-04-10T15:51:03.673",
    categoryId: 3,
  },
  1385: {
    groupId: 1385,
    name: "Noble Victories",
    abbreviation: "NVI",
    isSupplemental: false,
    publishedOn: "2011-11-16T00:00:00",
    modifiedOn: "2021-02-17T16:01:55.767",
    categoryId: 3,
  },
  1538: {
    groupId: 1538,
    name: "BW Trainer Kit: Excadrill & Zoroark",
    abbreviation: "BLW",
    isSupplemental: false,
    publishedOn: "2011-09-05T00:00:00",
    modifiedOn: "2019-02-05T23:12:28.713",
    categoryId: 3,
  },
  1424: {
    groupId: 1424,
    name: "Emerging Powers",
    abbreviation: "EPO",
    isSupplemental: false,
    publishedOn: "2011-08-31T00:00:00",
    modifiedOn: "2021-05-01T20:00:16.757",
    categoryId: 3,
  },
  1400: {
    groupId: 1400,
    name: "Black and White",
    abbreviation: "BLW",
    isSupplemental: false,
    publishedOn: "2011-04-25T00:00:00",
    modifiedOn: "2021-05-06T15:31:07.593",
    categoryId: 3,
  },
  1415: {
    groupId: 1415,
    name: "Call of Legends",
    abbreviation: "CL",
    isSupplemental: false,
    publishedOn: "2011-02-09T00:00:00",
    modifiedOn: "2021-03-25T16:06:00.523",
    categoryId: 3,
  },
  2332: {
    groupId: 2332,
    name: "Professor Program Promos",
    abbreviation: "PPP",
    isSupplemental: true,
    publishedOn: "2011-01-07T00:00:00",
    modifiedOn: "2020-08-12T14:53:10.173",
    categoryId: 3,
  },
  1381: {
    groupId: 1381,
    name: "Triumphant",
    abbreviation: "TM",
    isSupplemental: false,
    publishedOn: "2010-11-03T00:00:00",
    modifiedOn: "2021-05-08T15:17:22.433",
    categoryId: 3,
  },
  1403: {
    groupId: 1403,
    name: "Undaunted",
    abbreviation: "UD",
    isSupplemental: false,
    publishedOn: "2010-08-18T00:00:00",
    modifiedOn: "2021-04-25T11:58:24.693",
    categoryId: 3,
  },
  2205: {
    groupId: 2205,
    name: "Pikachu World Collection Promos",
    abbreviation: "PWCP",
    isSupplemental: true,
    publishedOn: "2010-07-08T00:00:00",
    modifiedOn: "2020-07-13T16:03:48.207",
    categoryId: 3,
  },
  1399: {
    groupId: 1399,
    name: "Unleashed",
    abbreviation: "UL",
    isSupplemental: false,
    publishedOn: "2010-05-12T00:00:00",
    modifiedOn: "2021-01-27T14:23:42.84",
    categoryId: 3,
  },
  1453: {
    groupId: 1453,
    name: "HGSS Promos",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2010-02-01T00:00:00",
    modifiedOn: "2020-12-16T22:45:29.767",
    categoryId: 3,
  },
  1391: {
    groupId: 1391,
    name: "Arceus",
    abbreviation: "AR",
    isSupplemental: false,
    publishedOn: "2009-11-04T00:00:00",
    modifiedOn: "2021-03-08T19:27:56.443",
    categoryId: 3,
  },
  1384: {
    groupId: 1384,
    name: "Supreme Victors",
    abbreviation: "SV",
    isSupplemental: false,
    publishedOn: "2009-08-19T00:00:00",
    modifiedOn: "2020-12-16T22:23:35.893",
    categoryId: 3,
  },
  2175: {
    groupId: 2175,
    name: "Burger King Promos",
    abbreviation: "BKP",
    isSupplemental: true,
    publishedOn: "2009-04-03T00:00:00",
    modifiedOn: "2021-04-09T13:47:00.373",
    categoryId: 3,
  },
  2155: {
    groupId: 2155,
    name: "Countdown Calendar Promos",
    abbreviation: "CCP",
    isSupplemental: true,
    publishedOn: "2008-10-01T00:00:00",
    modifiedOn: "2019-06-04T18:54:01.787",
    categoryId: 3,
  },
  1417: {
    groupId: 1417,
    name: "Legends Awakened",
    abbreviation: "LA",
    isSupplemental: false,
    publishedOn: "2008-08-20T00:00:00",
    modifiedOn: "2020-12-23T17:13:07.683",
    categoryId: 3,
  },
  1405: {
    groupId: 1405,
    name: "Great Encounters",
    abbreviation: "GE",
    isSupplemental: false,
    publishedOn: "2008-02-13T00:00:00",
    modifiedOn: "2021-03-22T16:35:52.37",
    categoryId: 3,
  },
  1541: {
    groupId: 1541,
    name: "DP Trainer Kit: Manaphy & Lucario",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2007-09-03T00:00:00",
    modifiedOn: "2021-03-05T12:37:26.653",
    categoryId: 3,
  },
  1430: {
    groupId: 1430,
    name: "Diamond and Pearl",
    abbreviation: "DP",
    isSupplemental: false,
    publishedOn: "2007-05-23T00:00:00",
    modifiedOn: "2021-02-17T16:00:30.287",
    categoryId: 3,
  },
  1421: {
    groupId: 1421,
    name: "Diamond and Pearl Promos",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2007-05-01T00:00:00",
    modifiedOn: "2021-02-19T22:31:52.163",
    categoryId: 3,
  },
  1411: {
    groupId: 1411,
    name: "Dragon Frontiers",
    abbreviation: "DF",
    isSupplemental: false,
    publishedOn: "2006-11-08T00:00:00",
    modifiedOn: "2021-05-05T22:17:47.57",
    categoryId: 3,
  },
  1395: {
    groupId: 1395,
    name: "Crystal Guardians",
    abbreviation: "CG",
    isSupplemental: false,
    publishedOn: "2006-08-30T00:00:00",
    modifiedOn: "2020-04-22T16:43:14.393",
    categoryId: 3,
  },
  1429: {
    groupId: 1429,
    name: "Delta Species",
    abbreviation: "DS",
    isSupplemental: false,
    publishedOn: "2005-10-31T00:00:00",
    modifiedOn: "2021-02-17T15:31:30.723",
    categoryId: 3,
  },
  1398: {
    groupId: 1398,
    name: "Unseen Forces",
    abbreviation: "UF",
    isSupplemental: false,
    publishedOn: "2005-08-22T00:00:00",
    modifiedOn: "2021-02-25T14:09:11.677",
    categoryId: 3,
  },
  1410: {
    groupId: 1410,
    name: "Emerald",
    abbreviation: "EM",
    isSupplemental: false,
    publishedOn: "2005-05-09T00:00:00",
    modifiedOn: "2021-02-17T16:07:40.053",
    categoryId: 3,
  },
  1404: {
    groupId: 1404,
    name: "Deoxys",
    abbreviation: "DX",
    isSupplemental: false,
    publishedOn: "2005-02-14T00:00:00",
    modifiedOn: "2021-02-17T15:17:45.69",
    categoryId: 3,
  },
  1853: {
    groupId: 1853,
    name: "EX Battle Stadium",
    abbreviation: "BST",
    isSupplemental: false,
    publishedOn: "2004-10-18T00:00:00",
    modifiedOn: "2020-10-05T16:38:48.087",
    categoryId: 3,
  },
  2214: {
    groupId: 2214,
    name: "Kids WB Promos",
    abbreviation: "KWBP",
    isSupplemental: false,
    publishedOn: "2004-07-02T00:00:00",
    modifiedOn: "2018-05-17T12:18:51.973",
    categoryId: 3,
  },
  1377: {
    groupId: 1377,
    name: "Team Magma vs Team Aqua",
    abbreviation: "MA",
    isSupplemental: false,
    publishedOn: "2004-03-15T00:00:00",
    modifiedOn: "2020-04-14T18:12:19.663",
    categoryId: 3,
  },
  1376: {
    groupId: 1376,
    name: "Dragon",
    abbreviation: "DR",
    isSupplemental: false,
    publishedOn: "2003-11-24T00:00:00",
    modifiedOn: "2017-08-28T20:10:13.49",
    categoryId: 3,
  },
  1397: {
    groupId: 1397,
    name: "Aquapolis",
    abbreviation: "AQ",
    isSupplemental: false,
    publishedOn: "2003-01-15T00:00:00",
    modifiedOn: "2021-04-19T12:43:33.943",
    categoryId: 3,
  },
  1455: {
    groupId: 1455,
    name: "Best of Promos",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2002-12-01T00:00:00",
    modifiedOn: "2020-12-16T19:22:22.443",
    categoryId: 3,
  },
  1373: {
    groupId: 1373,
    name: "Team Rocket",
    abbreviation: "TR",
    isSupplemental: false,
    publishedOn: "2000-04-24T00:00:00",
    modifiedOn: "2021-05-01T12:25:53.137",
    categoryId: 3,
  },
  630: {
    groupId: 630,
    name: "Fossil",
    abbreviation: "FO",
    isSupplemental: false,
    publishedOn: "1999-10-10T00:00:00",
    modifiedOn: "2021-05-09T16:29:16.357",
    categoryId: 3,
  },
  1418: {
    groupId: 1418,
    name: "WoTC Promo",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "1999-07-01T00:00:00",
    modifiedOn: "2021-02-12T13:57:32.913",
    categoryId: 3,
  },
  2289: {
    groupId: 2289,
    name: "Blister Exclusives",
    abbreviation: "BLE",
    isSupplemental: true,
    publishedOn: "1998-02-27T00:00:00",
    modifiedOn: "2021-05-07T19:39:41.89",
    categoryId: 3,
  },
  604: {
    groupId: 604,
    name: "Base Set",
    abbreviation: "BS",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-05-02T15:24:57.39",
    categoryId: 3,
  },
  1663: {
    groupId: 1663,
    name: "Base Set (Shadowless)",
    abbreviation: "BSS",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-04-23T14:46:07.607",
    categoryId: 3,
  },
  605: {
    groupId: 605,
    name: "Base Set 2",
    abbreviation: "BS2",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-05-01T17:05:43.973",
    categoryId: 3,
  },
  1407: {
    groupId: 1407,
    name: "Black and White Promos",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-02-19T18:11:01.967",
    categoryId: 3,
  },
  609: {
    groupId: 609,
    name: "DP Training Kit 1 Blue",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2020-07-02T17:38:08.217",
    categoryId: 3,
  },
  610: {
    groupId: 610,
    name: "DP Training Kit 1 Gold",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2020-07-02T17:46:52.493",
    categoryId: 3,
  },
  1426: {
    groupId: 1426,
    name: "Dragon Vault",
    abbreviation: "DRV",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-04-05T19:19:42.11",
    categoryId: 3,
  },
  1543: {
    groupId: 1543,
    name: "EX Trainer Kit 1: Latias & Latios",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-03-05T12:37:51.063",
    categoryId: 3,
  },
  1542: {
    groupId: 1542,
    name: "EX Trainer Kit 2: Plusle & Minun",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2017-03-01T11:03:36.18",
    categoryId: 3,
  },
  1375: {
    groupId: 1375,
    name: "Expedition",
    abbreviation: "EX",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-05-09T20:13:44.243",
    categoryId: 3,
  },
  1419: {
    groupId: 1419,
    name: "FireRed & LeafGreen",
    abbreviation: "RG",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2020-05-14T13:00:58.957",
    categoryId: 3,
  },
  1440: {
    groupId: 1440,
    name: "Gym Challenge",
    abbreviation: "G2",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-04-16T19:27:05.72",
    categoryId: 3,
  },
  1441: {
    groupId: 1441,
    name: "Gym Heroes",
    abbreviation: "G1",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-03-15T17:47:22.99",
    categoryId: 3,
  },
  1402: {
    groupId: 1402,
    name: "HeartGold SoulSilver",
    abbreviation: "HS",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-01-07T22:20:21.06",
    categoryId: 3,
  },
  1540: {
    groupId: 1540,
    name: "HGSS Trainer Kit: Gyarados & Raichu",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2020-03-05T19:59:10.57",
    categoryId: 3,
  },
  1416: {
    groupId: 1416,
    name: "Hidden Legends",
    abbreviation: "HL",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-02-17T16:01:01.79",
    categoryId: 3,
  },
  1379: {
    groupId: 1379,
    name: "Holon Phantoms",
    abbreviation: "HP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-02-17T14:28:44.617",
    categoryId: 3,
  },
  635: {
    groupId: 635,
    name: "Jungle",
    abbreviation: "JU",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-03-15T17:09:25.323",
    categoryId: 3,
  },
  1522: {
    groupId: 1522,
    name: "Kalos Starter Set",
    abbreviation: "KSS",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-01-27T14:33:50.447",
    categoryId: 3,
  },
  1378: {
    groupId: 1378,
    name: "Legend Maker",
    abbreviation: "LM",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-02-16T19:45:19.04",
    categoryId: 3,
  },
  1374: {
    groupId: 1374,
    name: "Legendary Collection",
    abbreviation: "LC",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-04-26T15:43:01.243",
    categoryId: 3,
  },
  1390: {
    groupId: 1390,
    name: "Majestic Dawn",
    abbreviation: "MD",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-05-02T15:58:38.037",
    categoryId: 3,
  },
  1401: {
    groupId: 1401,
    name: "McDonald's Promos 2011",
    abbreviation: "",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-01-12T21:47:29.07",
    categoryId: 3,
  },
  1427: {
    groupId: 1427,
    name: "McDonald's Promos 2012",
    abbreviation: "",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-01-12T21:47:30.93",
    categoryId: 3,
  },
  1368: {
    groupId: 1368,
    name: "Mysterious Treasures",
    abbreviation: "MT",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-03-23T14:41:32.067",
    categoryId: 3,
  },
  1444: {
    groupId: 1444,
    name: "Neo Destiny",
    abbreviation: "N4",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-04-12T17:38:02.93",
    categoryId: 3,
  },
  1434: {
    groupId: 1434,
    name: "Neo Discovery",
    abbreviation: "N2",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-04-28T15:01:34.61",
    categoryId: 3,
  },
  1396: {
    groupId: 1396,
    name: "Neo Genesis",
    abbreviation: "N1",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-04-30T23:15:59.637",
    categoryId: 3,
  },
  1389: {
    groupId: 1389,
    name: "Neo Revelation",
    abbreviation: "N3",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-05-01T19:09:25.287",
    categoryId: 3,
  },
  1412: {
    groupId: 1412,
    name: "Next Destinies",
    abbreviation: "NXD",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9363644Z",
    modifiedOn: "2021-03-28T13:51:00.36",
    categoryId: 3,
  },
  1423: {
    groupId: 1423,
    name: "Nintendo Promos",
    abbreviation: "PR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2021-04-09T14:21:30.637",
    categoryId: 3,
  },
  1406: {
    groupId: 1406,
    name: "Platinum",
    abbreviation: "PL",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2021-03-29T19:32:18.15",
    categoryId: 3,
  },
  1422: {
    groupId: 1422,
    name: "POP Series 1",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2017-08-16T19:07:00.143",
    categoryId: 3,
  },
  1447: {
    groupId: 1447,
    name: "POP Series 2",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2017-08-16T19:08:52.03",
    categoryId: 3,
  },
  1442: {
    groupId: 1442,
    name: "POP Series 3",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2020-12-02T14:49:16.743",
    categoryId: 3,
  },
  1452: {
    groupId: 1452,
    name: "POP Series 4",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2020-05-29T13:09:53.093",
    categoryId: 3,
  },
  1439: {
    groupId: 1439,
    name: "POP Series 5",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2020-06-26T14:27:49.117",
    categoryId: 3,
  },
  1432: {
    groupId: 1432,
    name: "POP Series 6",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2017-08-16T19:11:05.56",
    categoryId: 3,
  },
  1414: {
    groupId: 1414,
    name: "POP Series 7",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2019-05-16T15:24:10.96",
    categoryId: 3,
  },
  1450: {
    groupId: 1450,
    name: "POP Series 8",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2019-05-16T15:24:47.033",
    categoryId: 3,
  },
  1446: {
    groupId: 1446,
    name: "POP Series 9",
    abbreviation: "POP",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2021-04-05T18:52:20.01",
    categoryId: 3,
  },
  1383: {
    groupId: 1383,
    name: "Power Keepers",
    abbreviation: "PK",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:43:54.9373249Z",
    modifiedOn: "2020-10-28T12:37:43.783",
    categoryId: 3,
  },
  1367: {
    groupId: 1367,
    name: "Rising Rivals",
    abbreviation: "RR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2020-11-09T21:35:09.403",
    categoryId: 3,
  },
  1393: {
    groupId: 1393,
    name: "Ruby and Sapphire",
    abbreviation: "RS",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2019-12-13T14:51:01.79",
    categoryId: 3,
  },
  1433: {
    groupId: 1433,
    name: "Rumble",
    abbreviation: "RUM",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2021-03-03T21:59:25.413",
    categoryId: 3,
  },
  1392: {
    groupId: 1392,
    name: "Sandstorm",
    abbreviation: "SS",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2021-03-15T18:15:07.44",
    categoryId: 3,
  },
  1380: {
    groupId: 1380,
    name: "Secret Wonders",
    abbreviation: "SW",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2020-12-16T22:27:48.077",
    categoryId: 3,
  },
  1372: {
    groupId: 1372,
    name: "Skyridge",
    abbreviation: "SK",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2021-02-17T15:46:02.737",
    categoryId: 3,
  },
  648: {
    groupId: 648,
    name: "Southern Islands",
    abbreviation: "SI",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2017-08-28T20:05:17.82",
    categoryId: 3,
  },
  1369: {
    groupId: 1369,
    name: "Stormfront",
    abbreviation: "SF",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2021-02-13T05:47:33.62",
    categoryId: 3,
  },
  1428: {
    groupId: 1428,
    name: "Team Rocket Returns",
    abbreviation: "RR",
    isSupplemental: false,
    publishedOn: "2021-05-10T02:44:22.5357096Z",
    modifiedOn: "2021-03-05T14:12:50.093",
    categoryId: 3,
  },
};
