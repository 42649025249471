/* User
  {
    "firstName": "APITester",
    "lastName": "Tester",
    "password": "Testing123",
    "email": "pi0xig+41h4z1qnnr024@sharklasers.com"
  }
*/
const createUser = async (user, recaptcha) => {
  const data = {
    user,
    "g-recaptcha-response": recaptcha,
  };

  const res = await fetch(`/api/pokemon/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const jsonRes = await res.json();
  if (res.status !== 200) {
    throw jsonRes;
  }
  return jsonRes;
};

const UserApi = {
  createUser,
};

export default UserApi;
